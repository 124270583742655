<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading"
            >Log Details of {{ $route.query.name }}</span
          >
          <v-spacer></v-spacer>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="purchaseList.length > 0">
          <v-flex xs12>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Log Type</th>
                    <th class="text-left">Movement Status</th>
                    <th class="text-left">Stock</th>
                    <th class="text-left">Unit Price(INR)</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in purchaseList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.logtype"
                        class="text-capitalize"
                        style="font-family: kumbhMedium"
                        >{{ item.logtype }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.movementStatus"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.movementStatus }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.stock"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.stock }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.unitPrice"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.unitPrice }}</span
                      >
                    </td>
                    <td class="text-left">
                      <v-layout wrap justify-start>
                        <v-flex xs12 align-self-center text-left pa-2>
                          <v-dialog v-model="item.view" max-width="70%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                title="View"
                                color="#005f32"
                                v-on="on"
                                v-bind="attrs"
                                >mdi-eye</v-icon
                              >
                            </template>
                            <v-card>
                              <v-layout wrap>
                                <v-flex
                                  xs12
                                  align-self-center
                                  pa-4
                                  style="background: #005f32"
                                  text-left
                                >
                                  <span class="kumbhBold" style="color: #ffffff"
                                    >Log Details</span
                                  >
                                </v-flex>
                                <v-flex
                                  xs12
                                  align-self-center
                                  class="kumbhRegular"
                                >
                                  <v-layout wrap pa-12>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.create_date">
                                        Date :
                                        {{ item.create_date.slice(0, 10) }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.inventoryStockId.code"
                                    >
                                      <span v-if="item.inventoryStockId.code">
                                        Asset Code :
                                        {{ item.inventoryStockId.code }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span
                                        class="text-capitalize"
                                        v-if="item.logtype"
                                      >
                                        Log type : {{ item.logtype }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                    >
                                      <span v-if="item.stock">
                                        Stock :
                                        {{ item.stock }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.unitPrice"
                                    >
                                      <span v-if="item.unitPrice">
                                        Unit Price(INR) :
                                        {{ item.unitPrice }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.fromDepartmentId"
                                    >
                                      <span
                                        v-if="item.fromDepartmentId.deptName"
                                      >
                                        From Department :
                                        {{ item.fromDepartmentId.deptName }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.fromEmployeeId"
                                    >
                                      <span v-if="item.fromEmployeeId.name">
                                        From Employee :
                                        {{ item.fromEmployeeId.name }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.toDepartmentId"
                                    >
                                      <span v-if="item.toDepartmentId.deptName">
                                        To Department :
                                        {{ item.toDepartmentId.deptName }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.toEmployeeId"
                                    >
                                      <span v-if="item.toEmployeeId.name">
                                        To Employee :
                                        {{ item.toEmployeeId.name }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.fromProjectId"
                                    >
                                      <span v-if="item.fromProjectId.projectName">
                                        From Project :
                                        {{ item.fromProjectId.projectName }}
                                      </span>
                                    </v-flex>

                                    
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.toProjectId"
                                    >
                                      <span v-if="item.toProjectId.projectName">
                                        To Project :
                                        {{ item.toProjectId.projectName }}
                                      </span>
                                    </v-flex>
                                    
                                     

                                    <!-- <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.fromAssetTag"
                                    >
                                      <span v-if="item.fromAssetTag">
                                        From Asset tag :
                                        {{ item.fromAssetTag }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.toAssetTag"
                                    >
                                      <span v-if="item.toAssetTag">
                                        To Asset tag :
                                        {{ item.toAssetTag }}
                                      </span>
                                    </v-flex> -->

                                    
                                    

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.movementStatus"
                                    >
                                      <span v-if="item.movementStatus">
                                        Movement Status :
                                        {{ item.movementStatus }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.fundType"
                                    >
                                      <span v-if="item.fundType">
                                        Fund Type :
                                        {{ item.fundType }}
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.donorId"
                                    >
                                      <span v-if="item.donorId.name">
                                        Donor Name :
                                        {{ item.donorId.name }}
                                      </span>
                                    </v-flex>
                                    
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.donorId"
                                    >
                                      <span v-if="item.donorId.email">
                                        Donor Email :
                                        {{ item.donorId.email }}
                                      </span>
                                    </v-flex>
                                    
                                    <v-flex
                                      xs12
                                      sm6
                                      align-self-center
                                      text-left
                                      pa-1
                                      v-if="item.donorId"
                                    >
                                      <span v-if="item.donorId.country">
                                        Donor Country :
                                        {{ item.donorId.country }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      align-self-center
                                      text-right
                                      pa-1
                                      v-if="item.employeeId"
                                    >
                                      <i>
                                        <span v-if="item.employeeId.name">
                                          Action by :
                                          {{ item.employeeId.name }}
                                        </span>
                                      </i>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Log found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="40%">
          <v-card tile>
            <v-layout wrap>
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Category</span
                >
              </v-flex>
              <v-flex xs12 pa-5 pt-8 text-left>
                <v-text-field
                  v-model="editingitem.name"
                  label="Category Name"
                  :rules="[rules.required]"
                  outlined
                  autofocus
                  dense
                  required
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="edit(editingitem._id)"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      purchaseList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  mounted() {
    if (this.fromDate) {
      this.fromDate = null;
      // this.fromDate.setDate(this.fromDate.getDate() - 31);
      // this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate = null;
      // this.toDate.setDate(this.toDate.getDate());
      // this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  watch: {
    fromDate() {
      this.currentPage = 1;

      this.getData();
    },
    toDate() {
      this.currentPage = 1;
      this.getData();
    },

    currentPage() {
      this.getData();
    },
  },

  methods: {
    getData() {
      var invid = "";
      if (this.$route.query.invid) invid = this.$route.query.invid;
      else invid = null;

      this.appLoading = true;
      axios({
        url: "/asset/logs/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          assetId: this.$route.query.id,
          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
          inventoryStockId: invid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.purchaseList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
